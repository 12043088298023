<template>
  <!-- 联系我们 -->
  <div class="connect-us">
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <main>
      <div class="connect-us-title">
        <span>
          联系我们
        </span>
      </div>
      <!-- <div class="connect-us-phone">
        <div class="title">
          联系电话
        </div>
        <div class="details">
          办公室：0512-67164032、 0512-67164182<br>
          曹老师：13812633586<br>
          潘老师：15995401566<br>
          刘老师：15062326520<br>
          郭老师：18962116095
        </div>
      </div>
      <div class="connect-us-monitor">
        <div class="title">
          监督电话
        </div>
        <div class="details">人社局：0512-68709388</div>
      </div> -->
      <!-- <div class="connect-us-address">
        <div class="title">
          联系地址
        </div>
        <div class="details">苏州市电子信息技师学院（玻纤路3号，仁安街8号）</div>
      </div> -->
      <div style="padding: 30px;min-height: 500px;" ref="content" class="w-e-panel-content-table policy-content-text"
        data-slate-editor v-html="data.content">
      </div>
      <!-- <div class="connect-us-address">
        <div class="title" style="width: 6.6098%;">
        </div>
        <div class="details">
          <div id="container" style="width: 770px;
                height: 21.6875rem;
                background: #D9D9D9;
                margin-top: 0.75rem;
                margin-bottom: 1.5rem;">
          </div>
        </div>
      </div> -->
    </main>
    <footerBottom></footerBottom>
  </div>
</template>

<script>
import { MapCityName, MapKey } from '@/config/index'
import remoteLoad from '@/utils/remoteLoad.js'
import topNav from "@/components/nav/nav.vue"
import footerBottom from "@/components/footer/footer.vue"
import { httpNPGet, httpGet } from "@/utils/httpRequest";
import '@wangeditor/editor/dist/css/style.css'
export default {
  name: "ConnectUs",
  data() {
    return {
      pageIndex: 4,
      AMapUI: null,
      AMap: null,
      data: {

      }
    }
  },
  mounted() {
    // this.run();
    this.getData();
  },
  components: {
    topNav,
    footerBottom
  },
  methods: {
    // 获取富文本
    getData() {
      httpNPGet("/news-info/contact-us", (res) => {
        //console.log(res.data)
        if (res.data != null) {
          this.data = res.data
        } else {
          this.data = {}
        }
        // this.$nextTick(() => {
        //   let table = this.$refs.content.querySelector("table");
        //   if (table) {
        //     table.style.width = "100%";
        //   }
        // });
      }, (err) => {
        //// console.log(err)
      })
    },
    // 实例化地图
    initMap() {
      let _this = this;
      // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
      let AMap = this.AMap = window.AMap
      let mapConfig = {
        viewMode: '3D',
        showLabel: true,
        resizeEnable: true,
        zoom: 5,// 初始化地图时显示的地图放大等级
        cityName: MapCityName, //苏州
        mapStyle: 'normal'  //自定义样式:dark/fresh/grey/macaron
      }
      let map = new AMap.Map('container', mapConfig)
      // 清除地图覆盖物
      map.clearMap();

      let markers = [{ position: [120.59, 31.32] }];

      // // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
      markers.forEach(function (marker) {
        let markerMarker = new AMap.Marker({
          map: map,
          //     icon: marker.icon,
          //     id: marker.id,
          //     num: marker.num,
          //     updated: marker.updated,
          position: [marker.position[0], marker.position[1]],
          offset: new AMap.Pixel(-13, -30),
        });
        //
        markerMarker.on('click', markerClick);

        function markerClick() {
          _this.itemInfo = marker;
          _this.windowFlag = true
        }
      });
      //设置适合视图
      map.setFitView();
    },

    async run() {
      // 已载入高德地图API，则直接初始化地图
      if (window.AMap && window.AMapUI) {
        this.initMap()
        // 未载入高德地图API，则先载入API再初始化
      } else {
        await remoteLoad(`http://webapi.amap.com/maps?v=1.3&key=${MapKey}`)
        await remoteLoad('http://webapi.amap.com/ui/1.0/main.js')
        this.initMap()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .amap-logo {
  display: none;
  opacity: 0 !important;
}

::v-deep .amap-copyright {
  opacity: 0;
}

main {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto 0px;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 50px;
}

.connect-us {
  text-align: left;
  width: inherit;
  // background-color: rebeccapurple;


  .connect-us-title {
    cursor: pointer;
    width: 100%;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    align-items: center;
    margin-bottom: 10px;
    font-size: 24px;
    padding: 40px 0 30px 0;
    border-bottom: 1px solid #d5d5d584;
    text-align: center;
  }

  .connect-us-phone,
  .connect-us-monitor {
    display: flex;
    margin-bottom: 1.5rem;
  }

  .connect-us-monitor {

    .title,
    .details {
      height: 4.375rem;
      line-height: 4.375rem;
    }

  }

  .title {
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E454D;
    line-height: 1.1875rem;
    display: inline-block;
    margin-right: 5.5rem;
  }

  .details {
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    display: inline-block;
  }
}
</style>